// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cabinet-js": () => import("./../src/pages/Cabinet.js" /* webpackChunkName: "component---src-pages-cabinet-js" */),
  "component---src-pages-dentisterie-generale-js": () => import("./../src/pages/DentisterieGenerale.js" /* webpackChunkName: "component---src-pages-dentisterie-generale-js" */),
  "component---src-pages-implantologie-js": () => import("./../src/pages/Implantologie.js" /* webpackChunkName: "component---src-pages-implantologie-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-liens-js": () => import("./../src/pages/Liens.js" /* webpackChunkName: "component---src-pages-liens-js" */),
  "component---src-pages-protheses-js": () => import("./../src/pages/Protheses.js" /* webpackChunkName: "component---src-pages-protheses-js" */),
  "component---src-pages-urgences-js": () => import("./../src/pages/Urgences.js" /* webpackChunkName: "component---src-pages-urgences-js" */)
}

